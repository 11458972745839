<template>
  <v-container>
    <LoadingBanner v-if="loading"/>
    <ErrorBanner v-else-if="!loading && !dossierFound"/>
    <v-row v-else>
      <v-col offset="1" cols="10">
        <v-card min-height="50vh" flat :elevation="0" raised>
          <v-toolbar flat>
            <v-toolbar-title class="primary--text">Dossier</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-if="isIntern" v-model="deleteDossierDialog" max-width="600">
              <template v-slot:activator="{on, attrs}">
                <v-btn v-bind="attrs" v-on="on" color="red" class="white--text">
                  <v-icon>mdi-trash-can</v-icon>
                  Supprimer le dossier
                </v-btn>
              </template>
              <v-card>
                <v-toolbar color="red">
                  <v-toolbar-title class="white--text">Supprimer le dossier</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <p class="mt-4">Toutes les informations liés à ce dossiers seront perdus. Êtes-vous sûr de vouloir
                    continuer ?</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn :color="deleting ? 'grey':'red'" :disabled="deleting" class="white--text"
                         @click="deleteDossier">Supprimer
                  </v-btn>
                  <v-progress-circular color="red" indeterminate v-if="deleting"></v-progress-circular>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-badge dot color="red" v-if="selected_dossier.has_pinned_comments">
              <v-btn icon large color="primary" @click="showHistory = true">
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </v-badge>
            <v-btn v-else icon color="primary" @click="showHistory = true">
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row no-gutters v-if="selected_dossier.has_pinned_comments" justify="end">
            <p class="primary--text">Un message important n'a pas été validé.</p>
          </v-row>
          <v-row no-gutters>
            <v-col offset="2" cols="8">
              <vue-horizontal-timeline
                  v-if="dossier_stats !== null"
                  content-class="box"
                  timeline-background="#fff"
                  title-centered
                  content-centered
                  line-color="#208774"
                  :title-substr="2000"
                  :items="timelineStatuts"/>
            </v-col>

          </v-row>
          <v-toolbar flat>
            <v-toolbar-title class="secondary--text">Dossier #{{ selected_dossier.id }}</v-toolbar-title>
            <v-chip v-if="selected_dossier.statut !== null" color="blue" class="white--text ml-4">
              {{ selected_dossier.statut.name }}
            </v-chip>
            <v-chip v-else color="blue" class="white--text ml-4">Non défini</v-chip>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-row justify="center" class="px-4">
            <v-col lg="4" md="6" cols="12">
              <v-card color="primary">
                <v-card-title class="white--text justify-center">Bénéficiaire</v-card-title>
                <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ first_beneficiaire.contact.nom }}
                  {{ first_beneficiaire.contact.prenom }}</h2>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col lg="4" md="6" cols="12">
              <v-card color="blue">
                <v-card-title class="white--text justify-center">Montant des travaux</v-card-title>
                <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ selected_dossier.montant_travaux }} €</h2>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col lg="4" md="6" cols="12">
              <v-card color="orange">
                <v-card-title class="white--text justify-center text-no-wrap"><span>Ville du bien immobilier</span>
                </v-card-title>
                <v-card-subtitle class="ml-4 white--text text-center">
                  <h2 v-if="first_beneficiaire !== null">{{ first_beneficiaire.ville }}</h2>
                  <h2 v-else>Non défini</h2>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card color="blue" class="darken-3" width="100%" height="width">
                <v-card-title class="white--text justify-center">Référence</v-card-title>
                <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ selected_dossier.reference }}</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm edit-text="Modifier la référence" v-if="logged_user.group === 'Administrateur' || logged_user.group === 'Instructeur' || logged_user.group === 'Instructrice'"
                               edit-color="blue darken-3" key="referenceEdit" type="reference"
                               @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card color="red" width="100%" height="width">
                <v-card-title class="white--text justify-center">Statut</v-card-title>
                <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ selected_dossier.statut.name }}</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm  v-if="isIntern" edit-text="Modifier le statut" edit-color="red" key="statutEdit" type="statut"
                               @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card width="100%" color="grey" class="darken-2">
                <v-card-title class="white--text justify-center">Enquêteur</v-card-title>
                <v-card-subtitle class="white--text text-center font-weight-bold">
                  <h2 v-if="selected_dossier.enqueteur !== null">{{ selected_dossier.enqueteur }}</h2>
                  <h2 v-else>Non défini</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm v-if="isIntern" edit-color="grey darken-2" edit-text="Modifier l'enquêteur" key="enqueteurEdit"
                               type="enqueteur" @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card width="100%" color="primary">
                <v-card-title class="white--text justify-center">Instructeur</v-card-title>
                <v-card-subtitle class="white--text text-center font-weight-bold">
                  <h2 v-if="selected_dossier.instructeur !== null">{{ selected_dossier.instructeur.first_name }}
                    {{ selected_dossier.instructeur.last_name }}</h2>
                  <h2 v-else>Non défini</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm v-if="isIntern" edit-text="Modifier l'instructeur" edit-color="primary" key="instructeurEdit"
                               type="instructeur" @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card width="100%" color="green">
                <v-card-title class="white--text justify-center">Maître d'oeuvre</v-card-title>
                <v-card-subtitle class="white--text text-center font-weight-bold">
                  <h2 v-if="selected_dossier.maitre_oeuvre != null">{{ selected_dossier.maitre_oeuvre.first_name }}
                    {{ selected_dossier.maitre_oeuvre.last_name }} - {{ selected_dossier.maitre_oeuvre.nom_societe}}</h2>
                  <h2 v-else>Non défini</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm v-if="isIntern" edit-text="Modifier le maître d'oeuvre" edit-color="green" key="maitreOeuvreEdit"
                               type="maitre_oeuvre" @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card width="100%" color="orange">
                <v-card-title class="white--text justify-center">Conducteur de travaux</v-card-title>
                <v-card-subtitle class="white--text text-center font-weight-bold">
                  <h2 v-if="selected_dossier.conducteur_travaux != null">{{ selected_dossier.conducteur_travaux.first_name }}
                    {{ selected_dossier.conducteur_travaux.last_name }}</h2>
                  <h2 v-else>Non défini</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm v-if="isIntern" edit-text="Modifier le conducteur de travaux" edit-color="orange" key="conducteurTravauxEdit"
                               type="conducteur_travaux" @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
            <v-col lg="4" md="6" cols="12" class="d-flex align-center justify-center flex-column">
              <v-card width="100%" color="primary">
                <v-card-title class="white--text justify-center">Type de dossier</v-card-title>
                <v-card-subtitle class="white--text text-center font-weight-bold">
                  <h2 v-if="selected_dossier.type_dossier != null">{{selected_dossier.type_dossier}}</h2>
                  <h2 v-else>Non défini</h2>
                </v-card-subtitle>
              </v-card>
              <DossierEditForm v-if="isIntern" edit-text="Modifier le type de dossier" edit-color="primary" key="typeDossierEdit"
                               type="type_dossier" @reload="endDossierEdition"
                               :dossier="selected_dossier"/>
            </v-col>
          </v-row>
          <v-row class="mb-4" v-if="!hasGeoloc" justify="center" align-content="center">
            <p class="font-weight-bold">Aucune donnée de localisation n'existe pour ce bien immobilier</p>
          </v-row>
          <v-row class="mt-4" v-if="!hasGeoloc && isIntern" no-gutters justify="center" align-content="center">
            <v-btn @click="saveGeolocalisation" color="primary">Utiliser position actuelle comme géolocalisation</v-btn>
          </v-row>
          <v-row class="mt-4" v-else no-gutters justify="center" align-content="center">
            <v-btn v-if="isIntern" @click="sendGeolocalisation" color="primary">Partager la géolocalisation</v-btn>
          </v-row>
          <v-row no-gutters class="my-4">
            <v-col md="4" cols="6" v-if="selected_dossier.statut.numerical_value >= 10">
              <v-card :to="`/travaux/` + selected_dossier.id" class="d-flex flex-column">
                <v-icon color="primary" size="192">mdi-hammer-wrench</v-icon>
                <v-btn text color="primary">Accéder aux travaux</v-btn>
              </v-card>
            </v-col>
            <v-col md="4" cols="6" v-if="isIntern && selected_dossier.statut.numerical_value > 8">
              <v-card :to="`/finances/` + selected_dossier.id" class="d-flex flex-column">
                <v-icon color="primary" size="192">mdi-currency-eur</v-icon>
                <v-btn text color="primary">Accéder aux finances</v-btn>
              </v-card>
            </v-col>
          </v-row>
          <DossierInformations  v-if="isIntern"/>
          <v-row class="pt-4 px-7">
            <h2 class="primary--text">BÉNÉFICIAIRES</h2>
            <v-spacer></v-spacer>
            <v-dialog v-if="isIntern" v-model="createBenefDialog">
              <template v-slot:activator="{on, attrs}">
                <v-btn v-on="on" v-bind="attrs" color="secondary">
                  <v-icon>mdi-plus</v-icon>
                  Ajouter un bénéficiaire
                </v-btn>
              </template>
              <BeneficiaireForm v-if="isIntern" @close-dialog="endBeneficiaireCreation" operation="create"
                                :dossier_id="selected_dossier.id"/>
            </v-dialog>
          </v-row>
          <v-row class="mt-6" no-gutters>
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel v-for="beneficiaire in selected_dossier.beneficiaires" :key="beneficiaire.id">
                  <v-expansion-panel-header class="primary--text">
                    <h2>{{ beneficiaire.contact.prenom }} {{ beneficiaire.contact.nom }}</h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6">
                        <span class="body-1">Prénom</span><br>
                        <span class="body-2">{{ beneficiaire.contact.prenom }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Nom</span><br>
                        <span class="body-2">{{ beneficiaire.contact.nom }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Titre</span><br>
                        <span class="body-2">{{ beneficiaire.contact.titre }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Profession</span><br>
                        <span class="body-2">{{ beneficiaire.profession }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-2">Situation matrimoniale</span><br>
                        <span class="body-2">{{ beneficiaire.situation_matrimoniale }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">E-mail</span><br>
                        <span class="body-2">{{ beneficiaire.contact.email }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Téléphone mobile</span><br>
                        <span class="body-2">{{ beneficiaire.contact.tel_mobile }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Téléphone fixe</span><br>
                        <span class="body-2">{{ beneficiaire.contact.tel_fixe }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Ville</span><br>
                        <span class="body-2">{{ beneficiaire.ville }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Adresse</span><br>
                        <span class="body-2">{{ beneficiaire.adresse }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Code postal</span><br>
                        <span class="body-2">{{ beneficiaire.code_postal }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Date de naissance</span><br>
                        <span class="body-2">{{ getFormattedDate(beneficiaire.date_naissance) }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Âge</span><br>
                        <span class="body-2">{{ getAge(beneficiaire.date_naissance) }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">En situation de handicap</span><br>
                        <span class="body-2">{{ beneficiaire.handicap ? "Oui" : "Non" }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Nombre d'occupants</span><br>
                        <span class="body-2">{{ beneficiaire.nbre_occupants }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Nombre d'enfants à charge</span><br>
                        <span class="body-2">{{ beneficiaire.nbre_enfants_a_charge }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Part fiscale</span><br>
                        <span class="body-2">{{ beneficiaire.nbre_part_fiscale }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Revenus N-1</span><br>
                        <span class="body-2">{{ beneficiaire.revenus_n1 }} €</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Revenus N-2</span><br>
                        <span class="body-2">{{ beneficiaire.revenus_n2 }} €</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Éligible à l'APA</span><br>
                        <span class="body-2">{{ beneficiaire.eligible_apa ? "Oui" : "Non" }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Numéro allocataire</span><br>
                        <span class="body-2">{{ beneficiaire.numero_allocataire }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="body-1">Prêt CAF</span><br>
                        <span class="body-2" v-if="beneficiaire.pret_caf">{{ beneficiaire.pret_caf }} €</span>
                        <span class="body-2" v-else>Non applicable</span>
                      </v-col>
                      <v-col cols="12">
                        <v-dialog  v-if="isIntern" v-model="editBenefDialog" max-width="800">
                          <template v-slot:activator="{on, attrs}">
                            <v-btn v-on="on" v-bind="attrs" color="primary">Modifier</v-btn>
                          </template>
                          <BeneficiaireForm @close-dialog="editBenefDialog = false" :beneficiaire="beneficiaire"
                                            operation="update" :dossier_id="selected_dossier.id"/>
                        </v-dialog>
                        <v-dialog  v-if="isIntern" v-model="deleteBeneficiaireDialog" max-width="800">
                          <template v-slot:activator="{on, attrs}">
                            <v-btn v-on="on" v-bind="attrs" color="red" class="white--text ml-2">Supprimer</v-btn>
                          </template>
                          <v-card>
                            <v-toolbar color="red">
                              <v-toolbar-title class="white--text">Supprimer le bénéficiaire</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-4 body-1">
                              Voulez-vous vraiment supprimer ce bénéficiaire ? Les informations seront perdus.
                            </v-card-text>
                            <v-card-actions>
                              <v-btn text  v-if="isIntern" @click="deleteBeneficiaire(beneficiaire)" color="red">Supprimer</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12">
                        <Referent :beneficiaire="beneficiaire"/>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-expansion-panels>
            <v-expansion-panel  v-if="isIntern">
              <v-expansion-panel-header class="primary--text">
                <h2 class="text-uppercase">Documents</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BaseDocumentaire @reload="reloadDocuments" :dossier="selected_dossier"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <Attachments @reload="loadDossierPage" :dossier="selected_dossier"/>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary--text">
                <h2 class="text-uppercase">Bien immobilier</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pt-4 px-7">
                  <v-spacer></v-spacer>
                  <v-dialog v-if="selected_dossier.bien_immobilier === null" v-model="dialogs.bien_immobilier.create"
                            max-width="800">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn v-on="on" v-bind="attrs" color="primary">Associer un bien immobilier</v-btn>
                    </template>
                    <BienImmobilierForm v-if="isIntern" @close-dialog="closeDialogBienImmobilier"
                                        operation="create"
                                        :dossier_id="selected_dossier.id"
                                        :bien_immobilier="selected_dossier.bien_immobilier"/>
                  </v-dialog>
                  <v-dialog v-else v-model="dialogs.bien_immobilier.edit" max-width="800">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn v-on="on" v-bind="attrs" v-if="isIntern" color="primary">Modifier le bien immobilier</v-btn>
                    </template>
                    <BienImmobilierForm @close-dialog="closeDialogBienImmobilier" operation="update"
                                        :dossier_id="selected_dossier.id"
                                        :bien_immobilier="selected_dossier.bien_immobilier"/>
                  </v-dialog>
                </v-row>

                <v-row class="mx-4" v-if="selected_dossier.bien_immobilier !== null">
                  <v-col cols="6" lg="3">
                    <span class="body-1">Année de construction</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.annee_construction }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Type d'assainissement</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.assainissement }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Nombre d'étages</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.nbre_etage }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Nombre de pièces</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.nbre_pieces }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">PPRN</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.pprn }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Zone PLU</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.zone_plu }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Référence cadastrale</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.ref_cadastrale }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Bénéficiaire propriétaire du logement</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.proprio_logement ? "Oui" : "Non" }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Bénéficiaire propriétaire du terrain</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.proprio_terrain ? "Oui" : "Non" }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Surface brute</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.surface_brute }} m²</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Surface habitable</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.surface_habitable }} m²</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Surface plancher</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.surface_plancher }} m²</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Type de bien</span><br>
                    <span class="body-2">{{ selected_dossier.bien_immobilier.type_bien }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Déclaration préalable</span><br>
                    <span class="body-2">{{
                        selected_dossier.bien_immobilier.declaration_prealable ? "Oui" : "Non"
                      }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">Diagnostic BET</span><br>
                    <span class="body-2">{{
                        selected_dossier.bien_immobilier.diagnostic_bet ? "Oui" : "Non"
                      }}</span>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <span class="body-1">SPANC</span><br>
                    <span class="body-2">{{
                        selected_dossier.bien_immobilier.spanc ? "Oui" : "Non"
                      }}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row class="pt-4 pb-0 px-4" no-gutters v-if="photos.length > 0 && isIntern">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="primary--text">PHOTOS</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <v-col cols="8" offset="2">
                    <v-carousel height="400">
                      <v-carousel-item v-for="photo in photos" :key="photo.id">
                        <v-sheet height="50" color="primary" class="text-center d-flex align-center justify-center">
                          <span class="font-italic white--text">{{ photo.description }}</span>
                        </v-sheet>
                        <v-sheet color="grey">
                          <v-img max-height="400" contain :src="photo.file"></v-img>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <Attachments :key="selected_dossier.id" @reload="loadDossierPage" type="bien_immobilier" :dossier="selected_dossier"/>
          <v-row no-gutters class="my-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="primary--text text-uppercase">Liste des Devis</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12">
                    <Devis @reload="loadDossierPage" :dossier="selected_dossier"/>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row class="pa-2">
            <v-expansion-panels>
              <v-expansion-panel  v-if="isIntern">
                <v-expansion-panel-header class="text-center">

                  <h1 class="primary--text text-uppercase">Plan de financement</h1>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters align="start" justify="center">
                    <v-col md="6" cols="12">
                      <Configuration :dossier="selected_dossier" @reload="loadDossierPage"/>
                    </v-col>

                    <v-col  v-if="selected_dossier.type_dossier === 'AAH'" md="6" cols="12">
                      <h2 class="primary--text">Critères de plafonds AAH</h2>
                      <Financements @reload="loadDossierPage" :dossier='selected_dossier'/>
                      <v-card color="primary">
                        <v-card-title class="white--text d-block text-center">Total des plafonds</v-card-title>
                        <v-card-subtitle class="ml-4 white--text text-center"><h2>{{
                            selected_dossier.total_ressources
                          }} €</h2>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" cols="12">
                      <h2 class="primary--text">Emplois</h2>
                      <Honoraires :key="'Honoraires-' + selected_dossier.id" @reload="loadDossierPage" :dossier="selected_dossier"/>
                    </v-col>
                    <v-col md="6" cols="12">
                      <h2 class="primary--text">Ressources</h2>
                      <Subventions :key="'Subventions-' + selected_dossier.id" :dossier="selected_dossier"/>
                    </v-col>
                    <v-row>

                      <v-col lg="4" md="6" order-md="1" cols="12">
                        <v-card color="green">
                          <v-card-title class="white--text d-block text-center text-no-wrap">Coût total de l'opération
                          </v-card-title>
                          <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ selected_dossier.total_emplois }}
                            €</h2>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col lg="4" md="6" order-md="2" cols="12">
                        <v-card color="cyan">
                          <v-card-title class="white--text d-block text-center text-no-wrap">Équilibre des charges
                          </v-card-title>
                          <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ selected_dossier.delta }} €</h2>
                          </v-card-subtitle>
                        </v-card>
                        <div v-if="selected_dossier.delta !== 0" class="mt-2 text-center">
                          <span class="font-italic orange--text"><v-icon color="orange">mdi-exclamation</v-icon> Le plan de financement n'est pas équilibré.</span>
                        </div>
                      </v-col>
                      <v-col lg="4" md="6" order-md="3" cols="12">
                        <v-card color="purple">
                          <v-card-title class="white--text d-block text-center text-no-wrap">Total du financement
                          </v-card-title>
                          <v-card-subtitle class="ml-4 white--text text-center"><h2>{{ selected_dossier.total_finance }}
                            €</h2>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-col cols="12" v-if="selected_dossier.plan_financement != null"
                           class="d-flex justify-center align-center">
                      <p class="font-italic text-center">
                        Dernier plan de financement généré : <a target="_blank"
                                                                :href="selected_dossier.plan_financement">Plan de
                        financement {{ first_beneficiaire.contact.prenom }} {{ first_beneficiaire.contact.nom }}</a>
                        <br>
                        Généré le {{
                          getFormattedDate(selected_dossier.date_creation_plan_financement)
                        }}
                        <br>
                        <span v-if="selected_dossier.date_signature_plan_financement">
                          Signé le {{ getFormattedDate(selected_dossier.date_signature_plan_financement) }} par le
                          bénéficiaire
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center align-center">

                      <br>
                      <div class="mx-2">
                        <signature-prompt class="mx-2" v-model="signatureClient"
                                          :height="signature_client_position[0]"
                                          :width="signature_client_position[1]"
                                          button-text="Signature du client"></signature-prompt>
                      </div>
                      <div class="mx-2">
                        <signature-prompt v-model="signatureOperateur"
                                          :height="signature_operateur_position[0]"
                                          :width="signature_operateur_position[1]"
                                          button-text="Signature de l'opérateur"></signature-prompt>
                      </div>
                      <v-dialog width="800" v-model="generationDialog">
                        <template v-slot:activator="{on, attrs}">
                          <v-btn color="primary" class="white--text" v-on="on" v-bind="attrs">Générer le plan de
                            financement
                          </v-btn>
                        </template>
                        <v-card>
                          <v-toolbar color="warning" class="d-flex align-center justify-center">
                            <v-toolbar-title class="white--text">Générer le plan de financement</v-toolbar-title>
                          </v-toolbar>
                          <v-card-text class="mt-6">
                            <p class="body-1">Attention, générer un plan de financement supprimera l'ancien. Étes-vous
                              sur de vouloir continuer ?</p>
                          </v-card-text>
                          <v-card-actions class="d-flex justify-space-around">
                            <v-spacer></v-spacer>
                            <v-btn color="warning" class="white--text" @click="generatePlanFinancement">Générer</v-btn>
                            <v-btn color="grey" class="white--text" @click="generationDialog = false;">Annuler</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel  v-if="isIntern">
                <v-expansion-panel-header class="text-center">
                  <h1 class="primary--text text-uppercase">ÉTUDES ET MOE</h1>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <SaisieFactureInstructeur @reload="loadDossierPage" :dossier="selected_dossier"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-navigation-drawer height="95vh" v-if="!loading && dossierFound" width="600" absolute temporary right
                         v-model="showHistory">
      <Commentaires :dossier="selected_dossier"/>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import LoadingBanner from "@/components/home/LoadingBanner";
import ErrorBanner from "@/components/home/ErrorBanner";
import {DOSSIER_FETCH_STATUS} from "@/constants/DossierStatus";
import BeneficiaireForm from "@/components/dossiers/BeneficiaireForm";
import BienImmobilierForm from "@/components/bien_immobilier/BienImmobilierForm";
import Financements from "@/views/Financements";
import BaseDocumentaire from "@/views/BaseDocumentaire";
import Honoraires from "@/views/Honoraires";
import Attachments from "@/views/Attachments";
import DossierEditForm from "@/views/DossierEditForm";
import {DossierEtatMixin} from "@/mixins/DossierEtatMixin";
import Referent from "@/views/Referent";
import Devis from "@/views/Devis";
import {VueHorizontalTimeline} from "vue-horizontal-timeline";
import DossierInformations from "@/components/dossiers/DossierInformations";
import dayjs from "dayjs";
import Configuration from "@/views/Configuration";
import SignaturePrompt from "@/components/signature/SignaturePrompt";
import * as config from '@/config.json';
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import Subventions from "@/views/Subventions";
import Commentaires from "@/views/Commentaires";
import {AuthMixin} from "@/mixins/AuthMixin";
import SaisieFactureInstructeur from "@/views/SaisieFactureInstructeur.vue";

const POSITION_SIGNATURE_CLIENT_PLAN_FINANCEMENT = [99, 24, 209, 70]
const POSITION_SIGNATURE_OPERATEUR_PLAN_FINANCEMENT = [381, 26, 491, 72]

export default {
  name: "DossierView",
  components: {
    SaisieFactureInstructeur,
    Commentaires,
    Subventions,
    SignaturePrompt,
    Configuration,
    DossierInformations,
    Devis,
    Referent,
    DossierEditForm,
    Attachments,
    Honoraires,
    BaseDocumentaire,
    Financements,
    BienImmobilierForm,
    BeneficiaireForm,
    ErrorBanner,
    LoadingBanner,
    VueHorizontalTimeline
  },
  mixins: [DossierEtatMixin, DateUtilMixin, AuthMixin],
  async mounted() {
    let that = this;
    await this.loadDossierPage();
    await this.getDossiersStats();
    this.$root.$on("reload", async function () {
      await that.loadDossierPage();
    });
    this.$root.$on("refresh", async function () {
      console.log("On refresh")
      await that.$store.dispatch("dossier/fetchDossierById", that.selected_dossier.id);
    });
    this.$root.$on("reload-financements", async function () {
      await that.$store.dispatch("dossier/updateDossierFinanceData", that.selected_dossier.id);
    })
  },
  data() {
    return {
      console: console,
      loading: false,
      dossierFound: false,
      mode: 'new',
      editBenefDialog: false,
      createBenefDialog: false,
      deleteBeneficiaireDialog: false,
      dossierEditDialog: false,
      enqueteurEditDialog: false,
      dossier_stats: null,
      lat: null,
      long: null,
      dialogs: {
        bien_immobilier: {
          create: false,
          remove: false,
          edit: false,
        }
      },
      showHistory: false,
      signatureClient: "",
      signatureOperateur: "",
      generationDialog: false,
      deleting: false,
      deleteDossierDialog: false,
    }
  },
  computed: {
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    },
    fetch_status() {
      return this.$store.getters["dossier/getSingleItemFetchStatus"];
    },
    first_beneficiaire() {
      return this.selected_dossier.beneficiaires.length > 0 ? this.selected_dossier.beneficiaires[0] : null;
    },
    latitude() {
      return this.selected_dossier.bien_immobilier.latitude !== null ? parseFloat(this.selected_dossier.bien_immobilier.latitude) : 0;
    },
    longitude() {
      return this.selected_dossier.bien_immobilier.longitude !== null ? parseFloat(this.selected_dossier.bien_immobilier.longitude) : 0;
    },
    hasGeoloc() {
      return ![null, undefined].includes(this.selected_dossier?.bien_immobilier?.latitude)
          && ![null, undefined].includes(this.selected_dossier?.bien_immobilier?.longitude);
    },
    photos() {
      return this.selected_dossier.attachments.filter((attachment) => attachment.label.toLowerCase().indexOf("photo") !== -1);
    },
    timelineStatuts() {
      if (this.dossier_stats === null) {
        return null;
      }
      let that = this;
      return this.dossier_stats.others.map(function (statut, index) {
        let date = that.dossier_stats?.dates[index];
        return {
          title: statut,
          content: date !== null ? dayjs(date).format("DD/MM/YYYY") : "",
          boxCssClass: (statut === that.dossier_stats.active) ? "box_primary" : "box",
        }
      })
    },
    signature_client_position() {
      let position = POSITION_SIGNATURE_CLIENT_PLAN_FINANCEMENT;
      return [position[0] - position[2], position[1] - position[3]];
    },
    signature_operateur_position() {
      let position = POSITION_SIGNATURE_OPERATEUR_PLAN_FINANCEMENT;
      return [position[0] - position[2], position[1] - position[3]];
    },
    plan_financement_url() {
      return this.selected_dossier.plan_financement !== null ? config.BASE_URL + this.selected_dossier.plan_financement : "";
    }
  },
  methods: {
    async fetchDossier(dossierId) {
      await this.$store.dispatch("dossier/fetchDossierById", dossierId);
      if (this.fetch_status === DOSSIER_FETCH_STATUS.error) {
        this.dossierFound = false;
        this.loading = false;
      } else {
        this.dossierFound = true;
        this.loading = false;
      }
    },
    async deleteDossier() {
      this.deleting = true;
      try {
        await this.$store.dispatch("dossier/deleteDossier", this.selected_dossier);
        this.deleting = false;
        this.deleteDossierDialog = false;
        await this.$router.push("/dossier");
        this.$store.dispatch("annonce/annonceSuccess", "Dossier supprimé.");
      } catch (e) {
        this.$store.dispatch("annonce/annonceError", e.message);
        this.deleting = false;
        this.deleteDossierDialog = false;
      }
    },
    async getDossiersStats() {
      this.dossier_stats = await this.$store.dispatch("dossier/getDossierStats", this.selected_dossier.id);
    },
    async loadDossierPage() {
      this.loading = true;
      let dossierId = this.$route.params?.id;
      if (dossierId != null) {
        await this.fetchDossier(dossierId);
      } else {
        this.dossierFound = false;
        this.loading = false;
      }
    },
    endDossierEdition() {
      this.dossierEditDialog = false;
      this.loadDossierPage();
    },
    getAge(date_naissance) {
      if (date_naissance === null) return "Non défini";
      return (dayjs().year() - dayjs(date_naissance).year()).toString() + " ans";
    },
    async endBeneficiaireCreation() {
      this.createBenefDialog = false;
      await this.loadDossierPage();
    },
    async deleteBeneficiaire(beneficiaire) {
      let result = await this.$store.dispatch('beneficiaire/deleteBeneficiaire', beneficiaire);
      if (result === "success") {
        await this.$store.dispatch("annonce/annonceSuccess", "Bénéficiaire supprimé.");
        this.$store.dispatch("dossier/commentaires/postCommentaire", {
          dossier: this.selected_dossier.id,
          message: `Suppression du bénéficiaire pour le dossier ${this.selected_dossier.reference} nommé `
              + `${beneficiaire?.contact?.prenom} ${beneficiaire?.contact?.nom}`,
        })
      }
      this.deleteBeneficiaireDialog = false;
      await this.loadDossierPage();
    },
    async closeDialogBienImmobilier() {
      this.dialogs.bien_immobilier.edit = false;
      this.dialogs.bien_immobilier.create = false;
      await this.loadDossierPage();
    },
    async saveGeolocalisation() {
      let that = this;
      navigator.geolocation.getCurrentPosition(async (position) => {
        if (!that.selected_dossier.bien_immobilier) {
          await that.$store.dispatch("annonce/annonceWarning", "Bien immobilier non défini pour ce dossier, impossible d'enregistrer la géolocalisation.")
          return;
        }
        let bien_immobilier = {id: that.selected_dossier.bien_immobilier.id}
        bien_immobilier["latitude"] = position.coords.latitude;
        bien_immobilier["longitude"] = position.coords.longitude;
        let result = await that.$store.dispatch("bien_immobilier/patchBienImmobilier", bien_immobilier);
        if(result) that.$store.dispatch("annonce/annonceSuccess", "Géolocalisation enregistrée.")
        await this.loadDossierPage();

      }, () => {
        that.$store.dispatch("annonce/annonceError", "Une erreur est survenue lors de la récupération de la géolocalisation.")
      });

    },
    isMobile() {
      let check = false;
      (function (a) {
        /* eslint-disable-next-line no-useless-escape */
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    sendGeolocalisation() {
      if (this.isMobile()) {
        window.open(`https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`)
      } else {
        navigator.clipboard.writeText(`https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`);
        this.$store.dispatch("annonce/annonceSuccess", "Adresse copié dans le presse-papier.");
      }
    },
    async generatePlanFinancement() {
      this.generationDialog = false;
      this.generating = true;
      await this.$store.dispatch("financement/generatePlan", {
        dossier: this.selected_dossier.id,
        signature_client: this.signatureClient,
        signature_operateur: this.signatureOperateur,
      })
      this.loadDossierPage();
      this.generating = false;
    },
    async reloadDocuments() {
      let documents = await this.$store.dispatch("documents/fetchDocumentsByDossierId", this.selected_dossier.id);
      this.$store.commit("dossier/setDocuments", documents);
    }
  }
}
</script>

<style>
.box {
  box-shadow: none !important;
  border: none !important;
  left: -32px !important;
  text-align: center;
}

.box_primary {
  box-shadow: none !important;
  border: none !important;
  left: -32px !important;
  background: #208774 !important;
  color: white !important;
}

.box_primary::before {
  display: none;
}

.timeline {
  box-shadow: none !important;
  padding: 0 0 !important;
}

.timeline > ol > li:last-child {
  display: none !important;
}

.vue-horizontal-timeline {
  box-shadow: none !important;
}
</style>